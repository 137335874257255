<template class="body" v-if="routerAlive">
  <div id="app3" class="appclass">
    <Top ref="top" :white="$store.state.model.white"></Top>
    <!-- 主题 -->

    <div class="top-body">
      <!-- <img width="100%" src="../assets/text/guangg.jpg" v-show="banner_after_guanggao" /> -->
      <transition name="show_jianbian-1">
        <div class="hello" v-show="(enterbanner || isiPad) && !banner_after_guanggao">
          <div class="swiper-container" v-if="banner_list">
            <div class="swiper-wrapper">
              <div
                class="swiper-slide"
                v-for="(item, index) in banner_list"
                :key="index"
                :data-title="item.title"
                :data-nodes="item.subtitle"
                :data-url="item.url"
              >
                <!-- :style="{ backgroundImage: 'url(' + item.source + ')' }" -->
                <img width="100%" :src="item.source" alt="首页背景轮播海报大图" />
                <!-- <img width="100%" :src="item.source" /> -->
                <!-- <span class="menu-nodes-show">{{ item.nodes }}</span> -->
              </div>
            </div>
            <!-- 如果需要分页器 -->
            <transition name="show_bootom_banner">
              <div
                v-show="(enterbanner && entermenutotal) || isiPad"
                class="swiper-pagination"
                @mousemove="$event.target.click()"
              ></div>
            </transition>
          </div>
        </div>
      </transition>

      <transition name="show_bootom_center">
        <div class="top-content" v-show="(enterbanner && entermenutotal) || isiPad">
          <!-- 主题右侧菜单栏 -->
          <!-- <div class="top-bottom-box"> -->
          <!-- 主题下部组件 -->

          <div class="top-bottom" v-if="banner_list">
            <!-- 左侧频道 -->
            <div class="tp-channel" v-if="isNight">
              <template v-for="(item, index) in menutotal || menutotalBefore">
                <div class="tp-chan-box" :key="index" v-if="index < 8">
                  <a
                    class="channel-title"
                    :href="
                      YS.returnURL(
                        {
                          path: '/fil',
                          query: { category_id: item.id },
                        },
                        true,
                      )
                    "
                    target="_blank"
                  >
                    {{ item.name }}
                  </a>
                </div>
              </template>
            </div>
            <div class="tp-channel" v-else>
              <template v-for="(item, index) in menutotal || menutotalBefore">
                <div class="tp-chan-box" :key="index" v-if="index < 8 && item.show">
                  <a
                    v-if="item.symbol !== 'children'"
                    class="channel-title"
                    :href="
                      YS.returnURL(
                        {
                          path: '/fil',
                          query: { parent_category_id: item.id },
                        },
                        true,
                      )
                    "
                    target="_blank"
                  >
                    {{ item.name }}
                  </a>
                  <a
                    v-else-if="item.symbol === 'children'"
                    class="channel-title"
                    :href="childURL.CHILD_WEB_RUL"
                    target="_blank"
                  >
                    {{ item.name }}
                  </a>
                </div>
              </template>
            </div>

            <!-- 中间更多 -->
            <div class="tp-more">
              <div class="tp-chan-box-more" v-for="(item, index) in tpMore" :key="index">
                <a
                  :href="YS.returnURL({ path: '/play', query: { video_id: item.id } }, true)"
                  :title="item.title"
                  target="_blank"
                  class="channel-more"
                >
                  {{ item.title }}
                </a>
              </div>
            </div>
            <!-- 右侧其他 -->
            <div class="tp-other">
              <div class="tp_other_box" @click="tpo2">
                <div class="home_other_icon hoi2"></div>
                <a>下载APP</a>
                <!-- <span class="tp-new">new</span> -->
              </div>
              <!-- <div class="tp_other_box" @click="tpo5">
                <div class="home_other_icon hoi5"></div>
                <a>VIP特权</a>
              </div> -->
              <div class="tp_other_box" @click="tpo3">
                <!-- <img src="../assets/img/o3.png" /> -->
                <div class="home_other_icon hoi3"></div>
                <a>广告投放</a>
              </div>
              <!-- <div class="tp_other_box" @click="tpo4">
                <div class="home_other_icon hoi4"></div>
                <a>返回旧版</a>
              </div> -->
              <div class="tp_other_box" @click="tpo1" v-if="!isNight">
                <div class="home_other_icon hoi1"></div>
                <a>午夜版</a>
              </div>

              <!-- <div class="tp_other_box">
              <img src="../assets/img/logo2x.png" width="80" />
            </div> -->
            </div>
          </div>
          <!-- </div> -->
        </div>
      </transition>
    </div>
    <!-- <div
      style="
        height: auto;
        background: linear-gradient(137deg, rgb(255, 152, 0) 0%, rgb(253, 147, 113) 100%);
        border-radius: 8px;
        margin-bottom: 10px;
        display: flex;
      "
      class="guanggao"
    >
 
      <div
        direction="none"
        style="
          color: white;
          font-size: x-large;
          font-weight: bold;
          height: 100%;
          margin: 0 20px;
          padding: 3px 0;
        "
      >
        最新资源服务器维护中 部分无法播放
      </div>
    </div> -->
    <!-- 广告暂时写死 -->
    <div
      v-if="guanggaolist && guanggaolist['pc-home-banner-below']"
      class="guanggao"
      :class="{ guanggao_enter: !enterbanner }"
      @click="guanggaocli(guanggaolist['pc-home-banner-below'].url)"
      :style="{ backgroundImage: 'url(' + guanggaolist['pc-home-banner-below'].source + ')' }"
    ></div>
    <div
      v-if="guanggaolist2 && guanggaolist2['pc-home-banner-below-bottom']"
      class="guanggao_2"
      @click="guanggaocli(guanggaolist2['pc-home-banner-below-bottom'].url)"
      :style="{
        backgroundImage: 'url(' + guanggaolist2['pc-home-banner-below-bottom'].source + ')',
      }"
    ></div>
    <div
      v-if="guanggaolist3 && guanggaolist3['pc-home-banner-below-3']"
      class="guanggao_2"
      @click="guanggaocli(guanggaolist3['pc-home-banner-below-3'].url)"
      :style="{
        backgroundImage: 'url(' + guanggaolist3['pc-home-banner-below-3'].source + ')',
      }"
    ></div>
    <div
      v-if="guanggaolist4 && guanggaolist4['pc-home-banner-below-4']"
      class="guanggao_2"
      @click="guanggaocli(guanggaolist4['pc-home-banner-below-4'].url)"
      :style="{
        backgroundImage: 'url(' + guanggaolist4['pc-home-banner-below-4'].source + ')',
      }"
    ></div>
    <div class="guanggaolistheight" v-if="!guanggaolist"></div>
    <!-- 推荐/排行榜 -->

    <template v-for="(value, keyi) in menutotalhome">
      <div
        class="nodes-list"
        id="menutotal"
        :key="keyi"
        v-if="video_latest_list['i' + value.id] && video_rank_list['i' + value.id]"
      >
        <!-- 左侧推荐 -->

        <div class="list-left" v-if="video_latest_list['i' + value.id]">
          <!-- 头部 -->
          <transition name="showleft">
            <div
              class="ll-title"
              v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
            >
              <img src="../assets/img/list1.png" alt="推荐资源标题图标" />
              <a @click="$refs.top.clitip(value.id, value.pid, keyi)">{{ value.name }}</a>
            </div>
          </transition>
          <transition name="showleft">
            <Latest
              v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
              :recLatest="video_latest_list['i' + value.id]"
              :recID="'i' + value.id"
            ></Latest>
          </transition>
          <!-- 左侧主体 -->
        </div>
        <!-- 右侧排行榜 -->
        <div class="list-right" v-if="video_rank_list['i' + value.id]">
          <transition name="showright">
            <div
              class="ll-title"
              v-show="video_latest_list_show[keyi] && video_latest_list_sroll[keyi]"
            >
              <div>
                <img src="../assets/img/list1.png" alt="排行榜标题图标" />
                <a @click="$refs.top.clitip(value.id, value.pid, keyi)">{{ value.name }}·排行榜</a>
              </div>
              <div class="lead_leaderboard">
                <div
                  v-show="!loadCli"
                  class="lead_leaderboard_total"
                  :class="{
                    'lead_leaderboard-act': leadCheck[keyi] && leadCheck[keyi].type == 'total',
                  }"
                  @click="changeLeadTotal(value.id)"
                >
                  <span>总榜</span>

                  <div class="lead_leaderboard-icon"></div>
                </div>
                <div
                  v-show="!loadCli"
                  class="lead_leaderboard_month"
                  :class="{
                    'lead_leaderboard-act': leadCheck[keyi] && leadCheck[keyi].type == 'month',
                  }"
                  @click="changeLeadMonth(value.id)"
                >
                  <span>月榜</span>

                  <div class="lead_leaderboard-icon"></div>
                </div>
                <!-- <div class="lead_leaderboard_center">/</div> -->
                <div
                  v-show="!loadCli"
                  class="lead_leaderboard_week"
                  :class="{
                    'lead_leaderboard-act': leadCheck[keyi] && leadCheck[keyi].type == 'week',
                  }"
                  @click="changeLeadWeek(value.id)"
                >
                  <span>周榜</span>
                  <div class="lead_leaderboard-icon"></div>
                </div>
                <div class="dots-6" v-show="loadCli"></div>
              </div>
            </div>
          </transition>
          <transition name="showright">
            <Leadranking
              v-show="
                video_latest_list_show[keyi] &&
                video_latest_list_sroll[keyi] &&
                leadCheck[keyi].show
              "
              :class="{ leadDom: !video_latest_list_show[keyi] || !video_latest_list_sroll[keyi] }"
              :leadRanking="video_rank_list['i' + value.id]"
            ></Leadranking>
            <!-- :leadRanking="
                leadCheck[keyi] && leadCheck[keyi].type == 'month'
                  ? video_rank_list['m' + value.id]
                  : video_rank_list['i' + value.id]
              " -->
          </transition>
        </div>
      </div>
    </template>
    <Bottom v-if="(enterbanner && entermenutotal && bottomShow) || isiPad"></Bottom>
    <!-- Swiper -->
  </div>
</template>

<script>
let that;
// import { defineComponent } from 'vue'
// import HelloWorld from '@/components/hello-world.vue' // @ is an alias to ..
import Top from '../components/Top'; //导航top
import Bottom from '../components/Bottom'; //导航top
import Leadranking from '../components/Leadranking'; //排行榜
import Latest from '../components/Latest'; //最新 推荐
import 'swiper/dist/css/swiper.min.css';
// import 'swiper/dist/js/swiper.min';
import Swiper from 'swiper';
export default {
  components: {
    Top,
    Bottom,
    Leadranking,
    Latest,
  },
  name: 'Home',
  // computed: {
  //   video_rank_list: function () {
  //     return this.$store.state.video_rank_list;
  //   },
  //   video_latest_list: function () {
  //     return this.$store.state.video_latest_list;
  //   },
  // },
  computed: {
    offLight: function () {
      return this.$store.state.offLight;
    },
    isvip: () => {
      return that.$store.state.isvip;
    },
    // menutotal: () => {
    //   return that.$store.state.menutotal;
    // },
  },
  provide() {
    return {
      routerRefresh: this.routerRefresh,
    };
  },
  data() {
    return {
      childURL: window.g,
      loadCli: false, //打开加载提示
      weekTime: 0,
      monthime: 0,
      banner_after_guanggao: false,
      isiPad: false,
      entermenutotal: false,
      enterbanner: false,
      bottomShow: false,
      addmouseemterCode: false,
      routerAlive: true,
      sendcode: null,
      enter: false,
      video_rank_list: {},
      video_latest_list: {},
      tpMore: null, //轮播下面的更多
      itselfWhite: false, //自身白色导航栏效果
      leadCheck: [], //排行榜榜单类型
      isNight: window.g.isNight,
      leadType: {
        //这里跟据分类ID来判断一开始要传的类型
        102: { type: 'month' }, //综艺
        104: { type: 'total' }, //体育
        105: { type: 'month' }, //纪录片
        106: { type: 'month' }, //粤台专区
        107: { type: 'total' }, //儿童
      },

      video_latest_list_show: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      video_latest_list_sroll: [
        true,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
        false,
      ],
      menutotalhome: [], //排行榜和推荐
      menutotal: null,
      banner_list: null,
      clone_banner_list: [],
      guanggaolist: null, //广告信息
      guanggaolist2: null, //广告信息
      guanggaolist3: null, //广告信息
      guanggaolist4: null, //广告信息
      // fullHeight: '', //fullHeight: document.documentElement.clientHeight  屏幕高度 默认值
      iconlistde: require('../assets/img/b1.png'),
      iconlist: {
        1: require('../assets/img/b1.png'),
        28: require('../assets/img/b2.png'),
        44: require('../assets/img/b4.png'),
        54: require('../assets/img/b5.png'),
        64: require('../assets/img/b6.png'),
      },
      menutotalBefore: [],
      webwindowScroll: () => {},
    };
  },
  async created() {
    that = this;
    //独立站自动登录功能
    let url = location.search;
    let urlObj = {};
    if (url.indexOf('?') > -1) {
      var strdata = url.slice(url.indexOf('?') + 1).split('&');
      for (var i = 0; i < strdata.length; i++) {
        urlObj[strdata[i].split('=')[0]] = strdata[i].split('=')[1]; //如果出现乱码的话，可以用decodeURI()进行解码
      }
    }
    if (urlObj.expired_time && urlObj.token) {
      let locData = {};
      locData.expired_time = urlObj.expired_time;
      locData.token = urlObj.token;
      localStorage.setItem('token', JSON.stringify(locData));
      let data = await that.api.user.api_info({});
      if (data.user) {
        localStorage.setItem('user', JSON.stringify(data.user));
        that.$store.commit('changelogin');
        location.search = '';
      }
    }

    that.isiPad = /iPad/i.test(navigator.userAgent);

    let time = Math.round(new Date().getTime() / 1000).toString();
    that.weekTime = time - 7 * 24 * 60 * 60;
    that.monthTime = time - 30 * 24 * 60 * 60;
    // // that.$route.path;
    // // debugger;
    // 检测设备跳转
    //var ismob = /Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent);
    that.YS.toMobile('/');
    //获取轮播图缓存
    var bannerstore = JSON.parse(localStorage.getItem('banner_list'));
    if (bannerstore) {
      if (bannerstore.time > new Date().getTime()) {
        that.banner_list = bannerstore.data;
        setTimeout(() => {
          that.enterbanner = true;
        }, 50);
        //         this.$nextTick(() => {
        // that.enterbanner = true;
        //         });
      }
    }
    that.getbanner(); //获取轮播数据，并且初始化轮播器
    //获取广告缓存
    var guanggaostore = JSON.parse(localStorage.getItem('guanggaolist'));
    if (guanggaostore) {
      if (guanggaostore.time > new Date().getTime()) {
        // debugger;
        that.guanggaolist = guanggaostore.data['pc-home-banner-below'];
      }
    }
    //获取广告缓存
    var guanggaostore2 = JSON.parse(localStorage.getItem('guanggaolist2'));
    if (guanggaostore2) {
      if (guanggaostore2.time > new Date().getTime()) {
        that.guanggaolist2 = guanggaostore2.data['pc-home-banner-below-bottom'];
      }
    }
    //获取广告缓存
    var guanggaostore3 = JSON.parse(localStorage.getItem('guanggaolist3'));
    if (guanggaostore3) {
      if (guanggaostore3.time > new Date().getTime()) {
        that.guanggaolist3 = guanggaostore3.data['pc-home-banner-below-3'];
      }
    }
    //获取广告缓存
    var guanggaostore4 = JSON.parse(localStorage.getItem('guanggaolist4'));
    if (guanggaostore4) {
      if (guanggaostore4.time > new Date().getTime()) {
        that.guanggaolist4 = guanggaostore4.data['pc-home-banner-below-4'];
      }
    }
    //获取菜单存储
    var menutotalstore = JSON.parse(localStorage.getItem('menutotal'));
    if (menutotalstore) {
      if (menutotalstore.time > new Date().getTime()) {
        // that.$store.commit('changeEvery', { key: 'menutotal', value: menutotalstore.data });
        that.menutotalhome = menutotalstore.data;
        that.menutotal = menutotalstore.data;
        this.$nextTick(() => {
          that.entermenutotal = true;
          that.getTpMore();
        });
      }
    }
    //获取更多的储存
    if (this.YS.checkStore('tpMore')) that.tpMore = this.YS.checkStore('tpMore');

    this.webwindowScroll = this.YS.fnThrottle(this.windowScroll, 50);
    that.adall('pc-home-banner-below');
    that.adall2('pc-home-banner-below-bottom');
    that.adall3('pc-home-banner-below-3');
    that.adall4('pc-home-banner-below-4');
  },
  destroyed() {
    window.removeEventListener('scroll', this.webwindowScroll);
    // window.removeEventListener('scroll', this.mouseMoveBanner);
  },
  mounted() {
    // alert(document.documentElement.clientHeight);
    // alert(document.documentElement.clientWidth);

    window.addEventListener('scroll', this.webwindowScroll);
    // window.addEventListener('mousemove', this.mouseMoveBanner);
    // window.addEventListener('scroll', this.windowScroll, true);
    setTimeout(() => {
      that.enter = true;
    }, 100);
    setTimeout(() => {
      that.banner_after_guanggao = false;
    }, 5000);
    // that.getRL(); //获取推荐和排行榜

    // that.$store.state.menutotal.forEach((e) => {
    //   that.getRecommend(e.id); //获取最新的电影推荐列表
    //   that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    // });

    document.getElementById('topNav').classList.remove('top-nav-white');
    // var noneMap = document.querySelector('.top-nav-serch')
    // var noneSearch = document.querySelector('.top-nav-input')
    // var noneSearchBody = document.querySelector('.search-body')
    //关闭所有弹窗
    for (let v in this.$store.state.showWindows) {
      //关闭自身外其他弹窗
      this.$store.state.showWindows[v] = false;
    }

    // that.fullHeight = document.documentElement.clientHeight || document.body.clientHeight;
    // that.getElementTop(document.getElementsByClassName('nodes-list')[0]);
  },
  methods: {
    changeLead(type, id) {
      that.video_rank_list;
      that.leadCheck;
      type;
      id;
      that.getLeaderboard(id, type);
      // debugger;
    },
    //用于图片加载完毕替换图片
    loadOver(pic, i, list) {
      var newImg = new Image();
      newImg.src = pic;
      newImg.onload = () => {
        if (
          that.video_latest_list &&
          that.video_latest_list[list] &&
          that.video_latest_list[list][i]
        ) {
          // return true;
          that.video_latest_list[list][i].pic = newImg.src;
          that.video_latest_list[list][i].picLoad = true;
        }
      };
      // if (!that.$refs[src]) return;
      // let toTop = that.$refs[src].offsetTop;
      // console.log(toTop);
      // let toTop = src
    },
    // changeImg(data) {
    //   that.video_rank_list[data.list][data.index].pic = '';
    // },
    routerRefresh() {
      this.routerAlive = false;
      this.$nextTick(() => {
        this.routerAlive = true;
      });
    },
    //跳转筛选页
    tofil(id, pid) {
      that.YS.routetogo({
        path: '/fil',
        query: { parent_category_id: id, category_id: pid ? pid : '' },
      });
    },
    //获取 最新 的电影/电视剧...列表
    getRecommend(id, i) {
      if (window.g.isNight) {
        that.api.user.api_videolatest({ category_id: id }).then((data) => {
          that.$set(this.video_latest_list, 'i' + id, data.video_latest_list);
          // that.video_latest_list_show[i] = true;
          that.$set(this.video_latest_list_show, i, true);
          data.video_latest_list &&
            that.video_latest_list['i' + id].forEach((e, i) => {
              that.loadOver(e.pic, i, 'i' + id);
              that.video_latest_list['i' + id][i].pic = '';
            });
          setTimeout(() => {
            that.bottomShow = true;
          }, 2000);
        });
      } else {
        that.api.user.api_videolatest({ parent_category_id: id }).then((data) => {
          that.$set(this.video_latest_list, 'i' + id, data.video_latest_list);
          // that.video_latest_list_show[i] = true;
          that.$set(this.video_latest_list_show, i, true);
          that.video_latest_list['i' + id].forEach((e, i) => {
            that.loadOver(e.pic, i, 'i' + id);
            that.video_latest_list['i' + id][i].pic = '';
          });
          setTimeout(() => {
            that.bottomShow = true;
          }, 2000);
        });
      }
    },
    //获取 排行榜 的电影/电视剧...列表
    getLeaderboard(id, index) {
      // that.leadCheck.forEach((e) => {
      //   if (e.id === id && type != e.type) {
      //     let name = 'i';
      //     if (type == 'month') name = 'm';
      //     that.api.user
      //       .api_videorank({ parent_category_id: id, start_time: that.weekTime })
      //       .then((data) => {
      //         if (data.video_rank_list) {
      //           that.$set(this.video_rank_list, name + id, data.video_rank_list);
      //         } else {
      //           that.$set(this.video_rank_list, name + id, []);
      //         }
      //       });
      //   }
      // });
      let time = that.weekTime;
      let type = 'week';
      //判断是否限定榜单
      if (that.leadType[id]) {
        if (that.leadType[id].type == 'total') {
          time = null;
          type = 'total';
        }
        if (that.leadType[id].type == 'month') {
          time = that.monthTime;
          type = 'month';
        }
      }
      if (window.g.isNight) {
        that.api.user.api_videorank({ category_id: id, start_time: time }).then((data) => {
          if (data.video_rank_list) {
            that.leadCheck[index] = { type: type, id: id, show: true };
            that.$set(this.video_rank_list, 'i' + id, data.video_rank_list);
            that.$set(this.video_rank_list, type + id, data.video_rank_list);
          } else {
            that.leadCheck[index] = { type: type, id: id, show: true };
            that.$set(this.video_rank_list, 'i' + id, []);
            that.$set(this.video_rank_list, type + id, []);
          }
        });
      } else {
        that.api.user.api_videorank({ parent_category_id: id, start_time: time }).then((data) => {
          if (data.video_rank_list) {
            that.leadCheck[index] = { type: type, id: id, show: true };
            that.$set(this.video_rank_list, 'i' + id, data.video_rank_list);
            that.$set(this.video_rank_list, type + id, data.video_rank_list);
          } else {
            that.leadCheck[index] = { type: type, id: id, show: true };
            that.$set(this.video_rank_list, 'i' + id, []);
            that.$set(this.video_rank_list, type + id, []);
          }
        });
      }
    },
    //获取 排行榜 的电影/电视剧...列表  总榜
    changeLeadTotal(id) {
      if (that.loading) return;
      that.leadCheck.forEach((e) => {
        //定位找到ID
        if (e.id === id) {
          //定位找到type
          if (e.type !== 'total') {
            //判断VUE实例中是否存在该数据，不存在则发送接口获取
            if (!that.video_rank_list['total' + id]) {
              that.loadCli = true;

              that.api.user
                .api_videorank({ parent_category_id: id })
                .then((data) => {
                  if (data.video_rank_list) {
                    that.$set(this.video_rank_list, 'total' + id, data.video_rank_list);
                    e.type = 'total';
                    that.video_rank_list['i' + e.id] = data.video_rank_list;
                  } else {
                    that.$set(this.video_rank_list, 'total' + id, []);
                    e.type = 'total';
                    that.video_rank_list['i' + e.id] = [];
                  }
                })
                .finally(() => {
                  that.loadCli = false;
                });
            } else {
              e.type = 'total';
              that.video_rank_list['i' + e.id] = that.video_rank_list['total' + id];
            }
          } else {
            return;
          }
        }
      });
      // if(that.leadCheck)
    },
    //获取 排行榜 的电影/电视剧...列表  月榜
    changeLeadMonth(id) {
      if (that.loading) return;
      that.leadCheck.forEach((e) => {
        //定位找到ID
        if (e.id === id) {
          //定位找到type
          if (e.type !== 'month') {
            //判断VUE实例中是否存在该数据，不存在则发送接口获取
            if (!that.video_rank_list['month' + id]) {
              that.loadCli = true;

              that.api.user
                .api_videorank({ parent_category_id: id, start_time: that.monthTime })
                .then((data) => {
                  if (data.video_rank_list) {
                    that.$set(this.video_rank_list, 'month' + id, data.video_rank_list);
                    e.type = 'month';
                    that.video_rank_list['i' + e.id] = data.video_rank_list;
                  } else {
                    that.$set(this.video_rank_list, 'month' + id, []);
                    e.type = 'month';
                    that.video_rank_list['i' + e.id] = [];
                  }
                })
                .finally(() => {
                  that.loadCli = false;
                });
            } else {
              e.type = 'month';
              that.video_rank_list['i' + e.id] = that.video_rank_list['month' + id];
            }
          } else {
            return;
          }
        }
      });
      // if(that.leadCheck)
    },
    //获取 排行榜 的电影/电视剧...列表  周
    changeLeadWeek(id) {
      if (that.loadCli) return;
      that.leadCheck.forEach((e) => {
        //定位找到ID
        if (e.id === id) {
          //定位找到type
          if (e.type !== 'week') {
            //判断VUE实例中是否存在该数据，不存在则发送接口获取
            if (!that.video_rank_list['week' + id]) {
              that.loadCli = true;

              that.api.user
                .api_videorank({ parent_category_id: id, start_time: that.weekTime })
                .then((data) => {
                  if (data.video_rank_list) {
                    that.$set(this.video_rank_list, 'week' + id, data.video_rank_list);
                    e.type = 'week';
                    that.video_rank_list['i' + e.id] = data.video_rank_list;
                  } else {
                    that.$set(this.video_rank_list, 'week' + id, []);
                    e.type = 'week';
                    that.video_rank_list['i' + e.id] = [];
                  }
                })
                .finally(() => {
                  that.loadCli = false;
                });
            } else {
              e.type = 'week';
              that.video_rank_list['i' + e.id] = that.video_rank_list['week' + id];
            }
          } else {
            return;
          }
        }
      });

      // if (!that.loading) return;
      // that.leadCheck.forEach((e) => {
      //   if (e.id === id) {
      //     if (e.type === 'month') {
      //       that.loading = false;
      //       that.api.user
      //         .api_videorank({ parent_category_id: id, start_time: that.weekTime })
      //         .then((data) => {
      //           if (data.video_rank_list) {
      //             that.$set(this.video_rank_list, 'week' + id, data.video_rank_list);
      //             e.type = 'week';
      //             that.video_rank_list['i' + e.id] = data.video_rank_list;
      //           } else {
      //             that.$set(this.video_rank_list, 'week' + id, []);
      //           }
      //         })
      //         .finally(() => {
      //           that.loading = true;
      //         });
      //     } else {
      //       return;
      //     }
      //   }
      // });
    },
    //获取 轮播底部更多关键词
    async getTpMore() {
      if (that.tpMore) return;
      try {
        if (window.g.isNight) {
          let nightData = null;
          if (localStorage.getItem('nightData')) {
            nightData = JSON.parse(localStorage.getItem('nightData'));
          }
          let data = await that.api.user.api_videorank({
            start_time: that.weekTime,
            parent_category_id: nightData ? nightData.id : '',
          });
          if (!data || !data.video_rank_list) return;
          // that.$set(this.tpMore, data.video_rank_list);
          that.tpMore = data.video_rank_list;
        } else {
          let data = await that.api.user.api_videorank({ start_time: that.weekTime });
          if (!data || !data.video_rank_list) return;
          // that.$set(this.tpMore, data.video_rank_list);
          that.tpMore = data.video_rank_list;
        }
        //存储至本地
        that.YS.savestore('tpMore', that.tpMore, 600);
      } catch (error) {
        console.log(error);
      }
    },
    // 获取在页面上的绝对高度
    getElementTop(element) {
      var actualTop = element.offsetTop;
      var current = element.offsetParent;

      while (current !== null) {
        actualTop += current.offsetTop;
        current = current.offsetParent;
      }
      document.documentElement.clientHeight || document.body.clientHeight;
      return actualTop;
    },

    //获取推荐和排行榜
    getRL() {
      // that.$store.state.menutotal;
      // if (!that.menutotalhome[0]) {
      //   that.api.user.api_categoryhome({}).then((data) => {
      //     that.menutotalhome = [];
      //     data.category_list.forEach((e) => {
      //       if (e.pid == 0) {
      //         e.list = [];
      //         that.menutotalhome.push(e);
      //       }
      //     });
      //     //存储至本地
      //     that.YS.savestore('menutotalhome', that.menutotalhome, 600);
      //     this.$nextTick(() => {
      //       that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //       that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //       that.sendcode = 1;
      //     });
      //   });
      // } else {
      //   that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //   that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //   that.sendcode = 1;
      // }

      if (!that.menutotal) {
        that.api.user.api_categoryhome({}).then((data) => {
          that.menutotal = [];
          if (window.g.isNight) {
            const nightData = data.category_list.find((item) => item.symbol === 'night');
            data.category_list &&
              data.category_list.forEach((e) => {
                that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
                if (e.pid == nightData.id) {
                  that.menutotal.push(e);
                }
              });
            localStorage.setItem('nightData', JSON.stringify(nightData));
          } else {
            // console.log('获取菜单接口');
            data.category_list &&
              data.category_list.forEach((e) => {
                that.$store.commit('changeEvery', { key: 'menudist', value: e.name, key2: e.id });
                // vuex.menudist[e.id] = e.name;
                if (e.pid == 0) {
                  e.list = [];
                  // vuex.menutotal.push(e);
                  that.menutotal.push(e);
                  // that.$store.commit('changeEverypush', { key: 'menutotal', value: e });
                } else {
                  that.menutotal.forEach((a, i) => {
                    if (a.id == e.pid) {
                      // a.list.push(e);
                      that.menutotal[i].list.push(e);
                      // that.$store.commit('changeEverypush', {
                      //   key: 'menutotal',
                      //   value: e,
                      //   key2: i,
                      //   key3: 'list',
                      // });
                    }
                  });
                }
              });
          }
          that.menutotalhome = that.menutotal;
          this.$nextTick(() => {
            that.entermenutotal = true;
          });

          this.$nextTick(() => {
            that.getRecommend(that.menutotal[0]?.id), 0; //获取最新的电影推荐列表
            that.getLeaderboard(that.menutotal[0]?.id, 0); //获取最新的电影排行榜列表
            that.sendcode = 1;
          });
          //存储至本地
          that.YS.savestore('menutotal', that.menutotal, 600);
          that.getTpMore();
        });
      } else {
        that.getRecommend(that.menutotal[0].id, 0); //获取最新的电影推荐列表
        that.getLeaderboard(that.menutotal[0].id, 0); //获取最新的电影排行榜列表
        that.sendcode = 1;
      }

      // that.api.user.api_categoryhome({}).then((data) => {
      //   data.category_list.forEach((e) => {
      //     if (e.pid == 0) {
      //       e.list = [];
      //       that.menutotalhome.push(e);
      //     }
      //   });
      //   that.getRecommend(that.menutotalhome[0].id); //获取最新的电影推荐列表
      //   that.getLeaderboard(that.menutotalhome[0].id); //获取最新的电影排行榜列表
      //   that.sendcode = 1;
      //   // //遍历获取全部信息
      //   // that.menutotalhome.forEach((e) => {
      //   //   that.getRecommend(e.id); //获取最新的电影推荐列表
      //   //   that.getLeaderboard(e.id); //获取最新的电影排行榜列表
      //   // });
      // });
    },
    // //获取推荐和排行榜的收藏信息
    // getRLfav() {
    //   // that.$sotre.state.menutotal;
    //   if (that.menutotalhome) {
    //     that.api.user.api_categoryhome({}).then((data) => {
    //       that.menutotalhome = [];
    //       data.category_list.forEach((e) => {
    //         if (e.pid == 0) {
    //           e.list = [];
    //           that.menutotalhome.push(e);
    //         }
    //       });
    //       that.menutotalhome.forEach((e) => {
    //         that.getRecommend(e.id); //获取最新的电影推荐列表
    //         that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    //       });
    //     });
    //   } else {
    //     that.menutotalhome.forEach((e) => {
    //       that.getRecommend(e.id); //获取最新的电影推荐列表
    //       that.getLeaderboard(e.id); //获取最新的电影排行榜列表
    //     });
    //   }
    // },
    // 广告栏点击
    async guanggaocli(url) {
      try {
        window.open(url);
      } catch (error) {
        console.log(error.message);
      }
    },
    // 获取广告
    adall(symbols) {
      if (!that.guanggaolist || !that.guanggaolist[symbols]) {
        that.api.user.api_ad({ symbol: symbols, result: '1' }).then((data) => {
          if (data) {
            if (that.guanggaolist == null) that.guanggaolist = {};
            that.guanggaolist[symbols] = data.ad;
            //存储至本地
            that.YS.savestore('guanggaolist', that.guanggaolist, 600, symbols);
          }
        });
      }
    },
    // 获取第二条广告
    adall2(symbols) {
      if (!that.guanggaolist2 || !that.guanggaolist2[symbols]) {
        that.api.user.api_ad({ symbol: symbols, result: '1' }).then((data) => {
          if (data) {
            if (that.guanggaolist2 == null) that.guanggaolist2 = {};
            that.guanggaolist2[symbols] = data.ad;
            //存储至本地
            that.YS.savestore('guanggaolist2', that.guanggaolist2, 600, symbols);
          }
        });
      }
    },
    // 获取第三条广告
    adall3(symbols) {
      if (!that.guanggaolist3 || !that.guanggaolist3[symbols]) {
        that.api.user.api_ad({ symbol: symbols, result: '1' }).then((data) => {
          if (data) {
            if (that.guanggaolist3 == null) that.guanggaolist3 = {};
            that.guanggaolist3[symbols] = data.ad;
            //存储至本地
            that.YS.savestore('guanggaolist3', that.guanggaolist3, 600, symbols);
          }
        });
      }
    },
    // 获取第三条广告
    adall4(symbols) {
      if (!that.guanggaolist4 || !that.guanggaolist4[symbols]) {
        that.api.user.api_ad({ symbol: symbols, result: '1' }).then((data) => {
          if (data) {
            if (that.guanggaolist4 == null) that.guanggaolist4 = {};
            that.guanggaolist4[symbols] = data.ad;
            //存储至本地
            that.YS.savestore('guanggaolist4', that.guanggaolist4, 600, symbols);
          }
        });
      }
    },
    // 获取轮播图
    getbanner() {
      if (!that.banner_list) {
        // debugger;
        that.api.user
          .api_banner({ platform: 1, is_night: window.g.isNight ? 1 : 0 })
          .then((data) => {
            if (data) {
              that.banner_list = data.banner_list;
              //存储至本地
              that.YS.savestore('banner_list', data.banner_list, 600);
              this.$nextTick(() => {
                that.doswiper();
                that.enterbanner = true;
                // that.setSwiperInit();
              });
            }
          });
      } else {
        this.$nextTick(() => {
          that.doswiper();
          // that.setSwiperInit();
        });
      }
    },

    // setSwiperInit() {
    //   setTimeout(() => {
    //     debugger;
    //     document.getElementsByClassName('swiper-wrapper')[0].style.transform =
    //       'translate3d(0px, 0px, 0px)';
    //   }, 50);
    // },
    //点击午夜版
    async tpo1() {
      // that.$store.commit('loghtBool');
      // if (that.offLight) {
      //   // document.getElementById('app3').classList.add('app_offLight');
      //   document.getElementsByTagName('body')[0].classList.add('app_offLight');
      // } else {
      //   // document.getElementById('app3').classList.remove('app_offLight');
      //   document.getElementsByTagName('body')[0].classList.remove('app_offLight');
      // }
      that.$store.commit('getisvipCode');
      try {
        let data = await that.api.user.api_ad({ symbol: 'pc-midnight', result: 1 });
        if (data && data.ad) {
          let urlObj = localStorage.getItem('token');
          let urlData = '';
          if (urlObj) {
            urlObj = JSON.parse(urlObj);
            Object.keys(urlObj).forEach((e) => {
              urlData += e + '=' + urlObj[e] + '&';
            });
          }

          window.location.href = `${data.ad.url}?${urlData}`;
        }

        // if (that.isvip) {
        //   let data = await that.api.user.api_ad({ symbol: 'pc-midnight', result: 1 });
        //   if (data) {
        //     window.location.href = data.ad.url;
        //   }
        // } else {
        //   that.$refs.top.usepubilDialog({
        //     title: '该功能需要vip才能体验，请先开通vip！',
        //     type: 'needvip',
        //     code: false,
        //     failbutton: '开通vip',
        //   });
        // }
      } catch (error) {
        console.log(error.message);
      }
    },
    //点击下载app
    tpo2() {
      that.YS.routetogo({ path: '/downapp' }, true);
    },
    //点击广告投放
    tpo3() {
      that.YS.routetogo({ path: '/advertise' }, true);

      // that.$refs.top.symbol = 'purchase_vip_card';
      // that.$refs.top.customerService();
      // that.YS.gotoVip({}, 'open', 'kefugo');

      // if (that.$store.state.islogin) {
      //   that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      // } else {
      //   //添加登录后的方法队列
      //   that.$store.commit('addloginBefore', () => {
      //     that.YS.routetogo({ path: '/help', query: { kefu: 'advertising' } });
      //   });
      //   that.YS.gotoVip({}, 'open', 'loginReg'); //打开弹窗
      //   that.$refs.top.TOvercode(); //获取验证码
      // }
    },
    //返回旧版
    tpo4() {
      window.location.href = window.g.OLD_URL;
    },
    tpo5() {
      that.YS.routetogo(
        {
          path: '/privileges',
        },
        true,
      );
    },
    //改变导航栏颜色，true=白色（add class），false=透明色（remove class）
    async topChange(type) {
      try {
        that.$store.commit('getloghtBool');
        if (type) {
          if (this.offLight) {
            document.getElementById('topNav').classList.add('top-nav-black');
          } else {
            document.getElementById('topNav').classList.add('top-nav-white');
          }
          that.itselfWhite = true;
        } else {
          document.getElementById('topNav').classList.remove('top-nav-white');
          document.getElementById('topNav').classList.remove('top-nav-black');
          that.itselfWhite = false;
        }
      } catch (error) {
        console.log(error.message);
      }
    },
    // 滚动条距离页面顶部的距离
    windowScroll() {
      // 滚动条距离页面顶部的距离
      // 以下写法原生兼容
      // alert(document.body.clientWidth);
      // alert(document.getElementsByClassName('nodes-list')[0].offsetWidth);
      let scrollTop =
        window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop;
      that.menutotalhome.forEach((e, i) => {
        if (i > 0) {
          if (scrollTop >= 610 * (i - 1)) {
            if (
              (!that.video_latest_list['i' + e.id] || !that.video_rank_list['i' + e.id]) &&
              that.sendcode == i &&
              (that.sendcode += 1)
            ) {
              if (window.g.isNight) {
                that.getRecommend(e.id, i); //获取最新的电影推荐列表
                that.getLeaderboard(e.id, i); //获取最新的电影排行榜列表
              } else {
                //过滤show=0
                if (e.show) {
                  that.getRecommend(e.id, i); //获取最新的电影推荐列表
                  that.getLeaderboard(e.id, i); //获取最新的电影排行榜列表
                }
              }
              // console.log(that.menutotalhome);
            }
          }
          if (!that.itselfWhite && scrollTop > 5) {
            that.topChange(true);
          } else if (that.itselfWhite && scrollTop < 5) {
            that.topChange(false);
          }
        }
      });
      let showDom = document.getElementsByClassName('nodes-list');
      if (showDom.length) {
        showDom.forEach((e, i) => {
          if (that.video_latest_list_sroll[i]) return;
          if (e.offsetTop < document.body.clientHeight + scrollTop) {
            // that.video_latest_list_sroll[i] = true;
            that.$set(that.video_latest_list_sroll, i, true);
          }
        });
      }
    },

    // 初始化/开启轮播器
    doswiper() {
      new Swiper('.swiper-container', {
        loop: true,
        // 如果需要分页器
        pagination: '.swiper-pagination',
        autoplay: 3000,
        speed: 300,
        effect: 'fade',

        // slidesPerView: 'auto',
        observer: true,
        observeParents: true,
        // paginationType: 'custom',
        paginationBulletRender: function (swiper, index, className) {
          if (document.querySelectorAll('.swiper-slide')[index]) {
            document.querySelectorAll('.swiper-slide');
            var title = document
              .querySelectorAll('.swiper-slide')
              [index + 1].getAttribute('data-title');
            var nodes = document
              .querySelectorAll('.swiper-slide')
              [index + 1].getAttribute('data-nodes');
            return '<span class="' + className + '">' + title + '<p>' + nodes + '</p></span>';
          }
        },
        paginationClickable: true,
        // 如果需要前进后退按钮
        // nextButton: '.swiper-button-next',
        // prevButton: '.swiper-button-prev',
        // 如果需要滚动条
        // scrollbar: '.swiper-scrollbar',
      });
      var swiperslide = document.getElementsByClassName('swiper-slide');
      if (swiperslide) {
        swiperslide.forEach((item) => {
          item.addEventListener('click', () => {
            var url = item.getAttribute('data-url');
            window.open(url);
          });
        });
      }
      // setTimeout(() => {
      //   var swiperbutton = document.getElementsByClassName('swiper-pagination-bullet');
      //   if (swiperbutton) {
      //     swiperbutton.forEach((item) => {
      //       item.addEventListener('mouseenter', () => {
      //         // console.log(item);
      //         item.click();
      //       });
      //     });
      //     // swiperbutton[0].click();
      //   }
      // }, 1000);
    },
    // //添加鼠标放上去自动点击
    // addmouseemter() {
    //   if (that.addmouseemterCode) return;
    //   setTimeout(() => {
    //     var swiperbutton = document.getElementsByClassName('swiper-pagination-bullet');
    //     if (swiperbutton) {
    //       swiperbutton.forEach((item) => {
    //         item.addEventListener('mouseenter', () => {
    //           item.click();
    //         });
    //       });
    //     }
    //     that.addmouseemterCode = true;
    //   }, 2000);
    // },
  },
};
</script>

<style lang="scss">
html,
body {
  position: relative;
  height: 100%;
}
.hello {
  // max-height: 580px;
  // position: absolute;
  width: 100%;

  // overflow: hidden;
  position: relative;
  transform: 0.5s;
}

.hello .swiper-container {
  width: 100%;
  max-width: 1903px;
  max-height: 35vw;
  // min-height: ;
  // height: 100%;
  // height: 580px;
  // height: 666px;
  // img {
  //   min-height: 580px;
  // }
}

.hello .swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  cursor: pointer;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;

  overflow: hidden;
  background-repeat: no-repeat;
  background-size: cover;
  // margin-bottom: 50px;
  // width: 100% !important;
  // height: 580px;
  position: relative;
  color: #fff;
  overflow: hidden;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  img {
    width: 100%;
  }
}

.hello .swiper-pagination-bullet {
  transition: all 0.3s;
  width: 20px;
  height: 20px;
  text-align: center;
  line-height: 20px;
  font-size: 12px;
  color: #000;
  opacity: 1;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
}

.hello .swiper-pagination {
  flex-direction: column;
  justify-content: center;
  font-style: italic;
  font-weight: 300;
  font-size: 15px;
  z-index: 1;
  // background: yellow;
  display: flex;
  // height: 400px;
  width: 23% !important;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.3) 33%,
    rgba(0, 0, 0, 0.5) 50%,
    rgba(0, 0, 0, 0.6) 66%,
    rgba(0, 0, 0, 0.7) 100%
  );
  // background: linear-gradient(90deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.63) 100%);
  // background: linear-gradient(
  //   -90deg,
  //   rgba(9, 11, 33, 0.5),
  //   rgba(9, 11, 33, 0.05) 83%,
  //   rgba(9, 11, 33, 0.02) 89%,
  //   rgba(9, 11, 33, 0) 99%
  // );
  opacity: 1;
  position: absolute;
  // border-radius: 12px;
  overflow: hidden;
  // left: 50% !important;
  // margin-left: 535px;
  // bottom: 46px !important;
  left: 100% !important;
  margin-left: -23% !important;
  // bottom: 55px !important;
  // bottom: 95px !important;
  bottom: 0px !important;
  padding-bottom: 32px;
  // height: calc(100% - 155px);
  height: calc(100% - 32px);
  // top: 9% !important;
  span {
    font-weight: 400;
    margin: 0 !important;
    width: 55%;
    font-size: 15px;
    line-height: 22px;
    // color: #ffffff;
    color: hsla(0, 0%, 100%, 0.7);
    opacity: 1;
    // height: 22px;
    height: 7.5%;
    padding: 0 20px;
    display: flex;
    overflow: hidden;
    border-radius: 0 !important;
    line-height: 40px;
    flex-direction: column;
    // align-items: flex-start;
    align-items: flex-end;
    white-space: nowrap;
    font-style: initial;

    p {
      display: none !important;
      // width: 100px;
      height: 17px;
      font-size: 12px;
      font-family: PingFang SC;
      font-weight: 300;
      line-height: 17px;
      color: #ffffff;
      opacity: 1;
      font-style: initial;
      margin-top: 4px;
      display: flex;
    }
  }
}
.hello .swiper-pagination-bullet-active {
  font-weight: 600 !important;
  transition: all 0.3s;
  border-radius: 8px !important;
  color: #fff !important;
  // height: 58px !important;
  // height: 12% !important;

  // background: linear-gradient(91deg, #2196f3 0%, rgba(20, 155, 236, 0) 100%);
  // background: linear-gradient(270deg, #149bec 0%, rgba(20, 155, 236, 0) 100%);
  // background: linear-gradient(270deg, rgba(20, 155, 236, 0.68) 0%, rgba(20, 155, 236, 0) 100%);
  // background: linear-gradient(
  //   270deg,
  //   rgba(20, 155, 236, 0) 0%,
  //   rgba(20, 155, 236, 0.33) 10%,
  //   rgba(20, 155, 236, 0.68) 50%,
  //   rgba(20, 155, 236, 0) 100%
  // );
  width: 180px;
  display: flex;
  flex-direction: column;
  font-size: 22px !important;
  justify-content: center !important;
}
// .top-nav {
//   background: rgba(0, 0, 0, 0.6);
//   .tag-input {
//     background: #ffffff;
//     // border: 1px solid rgba(255, 255, 255, 0.43137254901960786);
//   }
//   .tag-span,
//   .right-span {
//     color: white;
//   }
//   .bubble {
//     background: #91d6ff;
//   }
//   .map_icon {
//     background-image: url(../assets/img/down2.png);
//   }
//   .ri_3 {
//     background-image: url(../assets/img/recording4.png);
//   }
//   .ri_4 {
//     background-image: url(../assets/img/notice4.png);
//   }
// }
.hello .swiper-slide img {
  // width: 1903px;
  width: 100%;
}
html {
  overflow-y: scroll !important;
}
</style>
<style scoped>
/* .top-nav {
  width: 100%;
  height: 60px;
  background: rgba(0, 0, 0, 0.6) !important;
  position: fixed;
  z-index: 100;
  box-shadow: 0px 3px 6px rgb(0 0 0 / 16%);
} */
</style>
